import { Link } from "gatsby"
import React from "react"
import Loading from "../../components/Loading"
import { useCartContext } from "./CartContext"
const CartTotals = ({
  onRemoveCoupon,
  updateLoading,
}) => {
  const { cartDetail,loading } = useCartContext()
  return (
    <div className="proceed-to-checkout">
      <div className="proceed-to-checkout-wrapper relative">
        {loading || updateLoading && (
          <Loading />
        )}
        <h2>Cart totals</h2>
        <table>
          <tbody>
            <tr>
              <th>Subtotal</th>
              <td>{cartDetail.subtotal}</td>
            </tr>
            {cartDetail.appliedCoupons?.map(item => (
              <tr
                key={item.code}
                className="cart-discount coupon-devtest"
              >
                <th>Coupon: {item.code}</th>
                <td data-title={`Coupon: ${item.code}`}>
                  -<span className="woocommerce-Price-amount amount">{item.discountAmount}</span>
                  <a
                    className="pointer"
                    onClick={() => onRemoveCoupon(item.code)}
                  > [Remove]</a>
                </td>
              </tr>
            ))}

            <tr>
              <th>Shipping</th>
              <td>Shipping costs are calculated during checkout.</td>
            </tr>
            <tr>
              <th>Total</th>
              <td>
                <strong>{cartDetail.total}</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="btn-proceed-to-checkout">
          <Link to="/checkout/">proceed to checkout</Link>
        </div>
      </div>
    </div>
  )
}

export default CartTotals