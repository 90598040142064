import React, { useEffect } from "react"
import "./cart.scss"
import Layout from "../../components/layout"
import { Link, graphql } from "gatsby"
import { useState } from "react"
import { APPLY_COUPON } from "../../graphql/mutations/coupon/applyCoupon"
import { useMutation } from "@apollo/client"
import CouponCode from "../../views/cart/CouponCode"
import CartTotals from "../../views/cart/CartTotals"
import { UPDATE_ITEM_QUANTITIES } from "../../graphql/mutations/cart/updateItemQuantities"
import Loading from "../../components/Loading"
import noImage from "../../assets/images/no-img.jpg"
import { ADD_MESSAGE, CART_IS_CART_EMPTY, useCartContext } from "../../views/cart/CartContext"
import MessageList from "../../views/cart/MessageList"
import { GET_SIMPLE_CART_QUERY_NAME } from "../../graphql/queries/cart/getSimpleCart"
import { REMOVE_COUPONS } from "../../graphql/mutations/coupon/removeCoupons"
import Seo from "../../components/seo"
import { toast } from "react-toastify"
import { isNumber } from "lodash"
const cloneCart = cart =>
  cart.map(item => {
    let product = item.product.node
    return {
      id: product.databaseId,
      productName: product.name,
      key: item.key,
      slug: product.slug,
      price: Number(product.salePrice || product.regularPrice),
      quantity: Number(item.quantity),
      subTotal: item.subtotal,
      thumbnail: product.featuredImage?.node.sourceUrl || noImage,
      variation: item.variation,
      type: product.__typename
    }
  })

function CartMain() {
  const [addedToCart, setAddedToCart] = useState([])
  const { cartItems, refetch, loading } = useCartContext()
  const [applyCoupon] = useMutation(APPLY_COUPON)
  const [updateCartItems, { loading: updateLoading }] = useMutation(UPDATE_ITEM_QUANTITIES, {
    refetchQueries: [GET_SIMPLE_CART_QUERY_NAME]
  })
  const [removeCoupon] = useMutation(REMOVE_COUPONS)
  const seo = {
    title: "Cart - Backa Australia",
    description: ""
  }

  const handleUpdateCartItems = items => {
    updateCartItems({
      variables: {
        input: {
          items: items
        }
      },
      onCompleted: () => {
        refetch()
      },
      onError: e => {
        toast.error(e.message)
      }
    })
  }
  const handleApplyCoupon = code => {
    const tempCode = code.toLowerCase()
    applyCoupon({
      variables: {
        input: {
          code: tempCode
        }
      },
      onCompleted: () => {
        refetch()
      },
      onError: e => {
        toast.error(e.message)
      }
    })
  }
  const handleRemoveCoupon = code => {
    const tempCode = code.toLowerCase()
    removeCoupon({
      variables: {
        input: {
          codes: [tempCode]
        }
      },
      onCompleted: () => {
        refetch()
      },
      onError: e => {
        toast.error(e)
      }
    })
  }
  const handleRemoveCartItem = item => {
    handleUpdateCartItems([{ key: item.key, quantity: 0 }])
  }
  const UpdateButton = () => (
    <button
      type="submit"
      onClick={() => {
        handleUpdateCartItems(
          addedToCart.map(item => ({
            key: item.key,
            quantity: item.quantity ? parseInt(item.quantity, 10) : 0
          }))
        )
      }}
      className={`form-button ${updateLoading ? "disable" : ""}`}
      aria-disabled="true"
    >
      update cart
    </button>
  )
  useEffect(() => {
    if (Array.isArray(cartItems) && cartItems.length > 0) {
      setAddedToCart(cloneCart(cartItems))
    } else {
      setAddedToCart([])
    }
  }, [cartItems])

  return (
    <Layout>
      <Seo title={seo.title} description={seo.description} />
      <div className="cart-section">
        <div className="cart-row">
          <div className="cart-column">
            <MessageList isCartEmpty={cartItems.length === 0} />
            {addedToCart.length > 0 && (
              <>
                <form
                  method="post"
                  action=""
                  className="relative"
                  onSubmit={e => e.preventDefault()}
                >
                  {(loading || updateLoading) && <Loading />}
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addedToCart.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <a
                              className="remover"
                              onClick={() => handleRemoveCartItem(item)}
                              href="#"
                            ></a>
                          </td>
                          <td>
                            <Link to={`/product/${item.slug}`}>
                              <img width={32} height={32} src={item.thumbnail} />
                            </Link>
                          </td>
                          <td>
                            <Link to={`/product/${item.slug}`}>
                              {item.productName}{" "}
                              {item.variation
                                ? item.variation.attributes.reduce((acc, item) => {
                                    if (!acc) {
                                      return ` - ${item.value}`
                                    }
                                    return `${acc}, ${item.value}`
                                  }, "")
                                : ""}
                            </Link>
                          </td>
                          <td>
                            {item.type === "VariableProduct"
                              ? item.subTotal
                              : `$${item.price.toFixed(2)}`}
                          </td>
                          <td>
                            <input
                              type="number"
                              inputMode="numeric"
                              className="quantity"
                              value={item.quantity}
                              onChange={({ target: { value } }) => {
                                setAddedToCart(
                                  addedToCart.map((cart, i) => {
                                    if (index === i) {
                                      cart.quantity = value
                                    }
                                    return cart
                                  })
                                )
                              }}
                            />
                          </td>
                          <td>{item.subTotal}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={6} className="actions">
                          <CouponCode onSubmit={code => handleApplyCoupon(code)} />
                          <UpdateButton />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="table-responsive">
                    {addedToCart.map((item, index) => (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <a
                              className="remover"
                              href="#"
                              onClick={() => handleRemoveCartItem(item)}
                            ></a>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Product:</td>
                          <td>
                            <Link to="#">{item.productName}</Link>
                          </td>
                        </tr>
                        <tr>
                          <td>Price:</td>
                          <td>
                            {item.type === "VariableProduct" ? item.subTotal : `$${item.price}`}
                          </td>
                        </tr>
                        <tr>
                          <td>Quantity:</td>
                          <td>
                            <input
                              type="number"
                              inputMode="numeric"
                              value={item.quantity}
                              onChange={({ target: { value } }) => {
                                setAddedToCart(
                                  addedToCart.map((item, i) => {
                                    if (index === i) {
                                      item.quantity = value
                                    }
                                    return item
                                  })
                                )
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Subtotal:</td>
                          <td>{item.subTotal}</td>
                        </tr>
                      </tbody>
                    ))}
                    <tbody>
                      <tr>
                        <td colSpan={6} className="actions">
                          <CouponCode onSubmit={code => handleApplyCoupon(code)} />
                          <UpdateButton />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
                <CartTotals
                  onRemoveCoupon={code => handleRemoveCoupon(code)}
                  updateLoading={updateLoading}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CartMain
