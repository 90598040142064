import { gql } from "@apollo/client"

export const UPDATE_ITEM_QUANTITIES = gql`
mutation updateItemQuantities ($input: UpdateItemQuantitiesInput!) {
    updateItemQuantities(input: $input) {
      cart {
        chosenShippingMethods
        contentsTax
        contentsTotal
        discountTax
        discountTotal
        displayPricesIncludeTax
        feeTax
        feeTotal
        isEmpty
        needsShippingAddress
        shippingTax
        shippingTotal
        subtotal
        subtotalTax
        total
        totalTax
      }
      clientMutationId
      items {
        key
        quantity
        subtotal
        subtotalTax
        tax
        total
      }
      removed {
         key
        quantity
        subtotal
        subtotalTax
        tax
        total
      }
      updated {
        key
        quantity
        subtotal
        subtotalTax
        tax
        total
      }
    }
  }
`