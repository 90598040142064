/* eslint-disable consistent-return */
import React, { useEffect } from "react"
import Message from "./Message"
import { REMOVE_MESSAGE, useCartContext } from "./CartContext"
import { Link } from "gatsby"

const MessageList = ({isCartEmpty}) => {
  const { state: { messages }, dispatch } = useCartContext()
  useEffect(() => {
    if (messages.length === 0) {return}

    const timeout = setTimeout(() => {
      dispatch({
        type: REMOVE_MESSAGE,
        payload: 0
      })
    }, 3000)
    return () => {
      clearTimeout(timeout)
    }
  }, [messages])

  return <>
    {messages.map((mess, index) => (
      <Message
        content={mess}
        key={index}
      />
    ))}
    {isCartEmpty && (
      <>
        <Message
          content='Your cart is currently empty.'
        />
        <div className="back-button">
          <Link to="/shop">return to shop</Link>
        </div>
      </>
    )}
  </>
}

export default MessageList