import { gql } from "@apollo/client"
import { CART_SIMPLE_FIELDS } from "../../fragments"
export const GET_SIMPLE_CART = gql`
${CART_SIMPLE_FIELDS}
query getSimpleCart {
    cart {
      ...cartSimpleFields
    }
}
`

export const GET_SIMPLE_CART_QUERY_NAME = "getSimpleCart"