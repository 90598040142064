import { gql } from "@apollo/client"

export const APPLY_COUPON = gql`
mutation applyCoupon($input: ApplyCouponInput!) {
    applyCoupon(input: $input) {
      applied {
        code
        description
        discountAmount(excludeTax: true)
        discountTax
      }
      cart {
        contentsTax
        contentsTotal
        discountTax
        discountTotal
        displayPricesIncludeTax
        feeTax
        feeTotal
        isEmpty
        needsShippingAddress
        shippingTax
        shippingTotal
        subtotal
        subtotalTax
        total
        totalTax
      }
      clientMutationId
    }
}
`