import React, { useState } from "react"

const CouponCode = ({
  code = "",
  onSubmit
}) => {
  const [coupon, setCoupon ] = useState(code)
  const handleSubmit = () => {
    if (onSubmit) { onSubmit(coupon) }
    setCoupon("")
  }

  const handleEnter = ({key}) => {
    if (key === "Enter") {
      handleSubmit()
    }
  }
  return (
    <div className="coupon">
      <input
        type="text"
        value={coupon}
        onKeyUp={handleEnter}
        onChange={({target: { value }}) => setCoupon(value)}
        placeholder="Coupon Code"
      />
      <button
        type="button"
        onClick={handleSubmit}
      >
        Apply coupon
      </button>
    </div>
  )
}

export default CouponCode